<template>
  <div class="abstract-data-table__td">
    <span v-if="titleHeader" class="abstract-data-table__td-desc">
      {{ titleHeader }}<br>
      {{ subtitleHeader }}
    </span>
    <strong class="abstract-data-table__td-title abstract-data-table__td-title--bold">
      <span v-if="titleValueOneLine" class="one-line" :title="titleValue">{{ titleValue }}</span>
      <span v-else>{{ titleValue }}</span>
    </strong>
    <span class="abstract-data-table__td-title abstract-data-table__td-title--subtitle">
      {{ subtitleValue }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'TitleWithSubtitleColumn',
  props: {
    titleHeader: {
      type: String,
      required: true
    },
    subtitleHeader: {
      type: String,
      required: true
    },
    titleValue: {
      type: [String, Number],
      required: true
    },
    titleValueOneLine: {
      type: Boolean,
      default: false
    },
    subtitleValue: {
      type: [String, Number],
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.one-line {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
